import { RolesTR, Permissions, SecondaryPermissions } from 'common/routes/types/roles.type'
import { SidebarOption } from 'common/typing/sidebar-options'

export const appName = 'Sözer'

export const sidebarWidth = '244px'

export const sidebarOptions: SidebarOption[] = [
  {
    icon: 'report',
    name: 'Rapor',
    path: '/reports',
    subItems: [],
    id: 'reports',
    permissions: RolesTR as any,
    newPermissions: ['read-report', 'admin-access'],
  },
  {
    icon: 'sozer',
    name: 'Koleksiyonlar',
    path: '/products/list',
    id: 'products',
    permissions: RolesTR as any,
    subItems: [],
    newPermissions: [
      'admin-access',
      'manage-product',
      'manage-monacoProduct',
      'manage-productSubCategory',
      'read-product',
      'read-monacoProduct',
    ],
  },
  {
    icon: 'time_circle',
    name: 'Bekleyen',
    path: '/products/pending-products',
    subItems: [],
    id: 'pending',
    permissions: RolesTR as any,
    newPermissions: ['manage-product', 'read-pending-product', 'admin-access'],
  },

  {
    icon: 'explosion',
    name: 'Hatalar',
    path: '/products/product-error',
    subItems: [],
    id: 'errors',
    permissions: RolesTR as any,
    newPermissions: ['manage-product', 'problem-order', 'admin-access'],
  },

  {
    icon: 'cart_outline',
    name: 'Sepetim',
    path: '/orders/create',
    id: 'orders',
    permissions: RolesTR as any,
    subItems: [],
    newPermissions: ['manage-order', 'create-order', 'update-order', 'delete-order', 'admin-access'],
  },
  {
    icon: 'document',
    name: 'Siparişler',
    path: '/orders/list',
    id: 'ordersList',
    permissions: RolesTR as any,
    subItems: [],
    newPermissions: ['manage-order', 'read-order', 'admin-access'],
  },
  {
    icon: 'folks',
    name: 'Müşteriler',
    path: '/customers/list',
    id: 'customers',
    permissions: RolesTR as any,
    subItems: [],
    newPermissions: ['manage-customer', 'admin-access'],
  },

  {
    icon: 'cube',
    name: 'Seçenekler',
    path: '/options/list',
    id: 'options',
    subItems: [],
    permissions: RolesTR as any,
    newPermissions: [
      'manage-option',
      'manage-user',
      'manage-role',
      'manage-department',
      'manage-stone',
      'manage-chain',
      'manage-monacoProduct',
      'manage-lock',
      'manage-printer',
      'manage-productSubCategory',
      'manage-slider',
      'admin-access',
    ],
  },
  {
    icon: 'chat',
    name: 'Mesajlar',
    path: '/chat',
    id: 'chat',
    subItems: [],
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  {
    icon: 'list_view',
    name: 'Notlar',
    path: '/todoList',
    subItems: [],
    id: 'todoList',
    permissions: RolesTR as any,
    newPermissions: ['manage-todoList', 'create-todoList', 'read-todoList', 'update-todoList', 'delete-todoList', 'admin-access'],
  },
  {
    icon: 'bell',
    name: 'Bildirimler',
    path: '/notifications',
    id: 'notifications',
    subItems: [],
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  // {
  //   icon: 'bell',
  //   name: 'Logs',
  //   path: '#',
  //   permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim'],
  //   id: 'logs',
  //   subItems: [
  //     {
  //       name: 'Sipariş Logs',
  //       path: '/logs/list?mode=order',
  //       permissions: ['Yönetim', 'Satış Yönetim', 'Üretim Yönetim'],
  //     },
  //   ],
  // },
  // {
  //   icon: 'logout',
  //   name: 'Çıkış Yap',
  //   path: '#',
  //   id: 'logout',
  //   permissions: RolesTR as any,
  //   newPermissions: Permissions as any,
  //   subItems: [],
  // },
]

export const sidebarSecondaryOptions: SidebarOption[] = [
  {
    icon: 'profile',
    name: 'İk',
    path: '#',
    subItems: [
      {
        icon: 'setting',
        name: 'İzin',
        path: '/automation/leave/list',
        permissions: RolesTR as any,
        secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-leave', 'read-secondary-leave'],
      },
      {
        icon: 'setting',
        name: 'Mesai',
        path: '/automation/overtime',
        permissions: RolesTR as any,
        secondaryPermissions: [
          '*-secondary',
          'admin-secondary-access',
          'manage-secondary-officerOvertime',
          'read-secondary-officerOvertime',
        ],
      },
      {
        icon: 'setting',
        name: 'Personel',
        path: '/automation/employees',
        permissions: RolesTR as any,
        secondaryPermissions: [
          '*-secondary',
          'admin-secondary-access',
          'manage-secondary-employeeOfficer',
          'read-secondary-employeeOfficer',
          'manage-secondary-leaveCountHistory',
          'read-secondary-leaveCountHistory',
        ],
      },
      {
        icon: 'setting',
        name: 'İçecekler',
        path: '/automation/drinks',
        permissions: RolesTR as any,
        secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-drinkOrder', 'read-secondary-drinkOrder'],
      },
      {
        icon: 'setting',
        name: 'Seçenekler',
        path: '/automation/list',
        permissions: RolesTR as any,
        secondaryPermissions: [
          '*-secondary',
          'admin-secondary-access',
          'manage-secondary-leaveType',
          'manage-secondary-department',
          'manage-secondary-role',
          'manage-secondary-service',
          'manage-secondary-neighbourhood',
          'manage-secondary-announcement',
          'manage-secondary-foodMenu',
          'manage-secondary-coffeeTime',
          'manage-secondary-drinkType',
        ],
      },
    ],
    id: 'automation',
    permissions: RolesTR as any,
    secondaryPermissions: [
      '*-secondary',
      'admin-secondary-access',
      'manage-secondary-leave',
      'read-secondary-leave',
      'manage-secondary-officerOvertime',
      'read-secondary-officerOvertime',
      'manage-secondary-employeeOfficer',
      'read-secondary-employeeOfficer',
      'manage-secondary-leaveType',
      'manage-secondary-department',
      'manage-secondary-role',
      'manage-secondary-service',
      'manage-secondary-neighbourhood',
      'manage-secondary-leaveCountHistory',
      'read-secondary-leaveCountHistory',
      'manage-secondary-announcement',
      'manage-secondary-foodMenu',
      'manage-secondary-coffeeTime',
      'manage-secondary-drinkType',
      'manage-secondary-drinkOrder',
    ],
  },
]
